import '../styles/home.css'

const Home = () => {
    return(
        <div className='home'>
        </div>
    )
}


export default Home