import Home from "./Components/Home";
import About from "./Components/About";
import Contact from "./Components/Contact";
import "./App.css";
import "./styles/nav.css";
import { useEffect, useState } from "react";
import { BsCircleFill } from "react-icons/bs";

let App = () => {
  let [currScreen, setCurrScreen] = useState(1);
  let [highlightPosition, setHighlightPosition] = useState(0);
  let screens = ["Contact", "Home", "About"];

  useEffect(() => {
    setHighlightPosition(108 * currScreen - 54 * (screens.length - 1));
    // eslint-disable-next-line
  }, [currScreen]);

  return (
    <div
      id="container"
      className="container"
      style={{ left: `-${100 * currScreen}%` }}
    >
      <nav className="nav">
        {screens.map((s, i) => {
          return (
            <button onClick={() => setCurrScreen(i)} key={i}>
              {s}
            </button>
          );
        })}
        <span
          style={{
            transform: `translate(calc(-50% + ${highlightPosition}px), -50%)`,
          }}
          className="nav-highlight"
        ></span>
      </nav>

      <div id="butterfly" className="butterfly">
        <span className="wing left-wing">ʚ</span>
        <span>ĭ</span>
        <span className="wing right-wing">ɞ</span>
      </div>

      <div className="splash">
        <BsCircleFill className="bubble b1" fill="#5EFCE880" />
        <BsCircleFill className="bubble b2" fill="#736EFE80" />
        <BsCircleFill className="bubble b3" fill="#736EFE80" />
        <BsCircleFill className="bubble b4" fill="#F9FE6E80" />
        <BsCircleFill className="bubble b5" fill="#736EFE80" />
      </div>

      <Contact allowScroll={false}/>
      <Home allowScroll={true}/>
      <About allowScroll={false}/>
    </div>
  );
};

export default App;
