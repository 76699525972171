import '../styles/about.css'
import Book from './Book'

const About = () => {
    return(
        <div className='about'>
            <Book />
        </div>
    )
}


export default About