import { useEffect, useState } from "react";
import "../styles/contact.css";
import emailjs, { init } from "emailjs-com";
import { SiTiktok } from "react-icons/si";
import { FaTwitch } from "react-icons/fa";
import { FiCheck } from "react-icons/fi";
import { ReactComponent as Discord } from "../icons/Discord-Logo-White.svg";

const Contact = () => {
  let [validInputs, setValidInputs] = useState(false);
  let [loading, setLoading] = useState(false);
  let [emailSent, setEmailSent] = useState(false);
  let [emailSuccess, setEmailSuccess] = useState(false);
  let [inputsInfo, setInputsInfo] = useState([]);

  useEffect(() => {
    init("user_xmtodGNW9sdoZ099d0X6v");
    setInputsInfo([
      {
        name: "fname",
        label: "First Name",
        type: "text",
        value: "",
        valid: null,
      },
      {
        name: "lname",
        label: "Last Name",
        type: "text",
        value: "",
        valid: null,
      },
      {
        name: "fromEmail",
        label: "Email",
        type: "text",
        value: "",
        valid: null,
      },
      {
        name: "message",
        label: "Message",
        type: "textarea",
        value: "",
        valid: null,
      },
    ]);
  }, []);

  const inputsValid = () => {
    setValidInputs(true);
    inputsInfo.forEach((i) => {
      !i.valid && setValidInputs(false);
    });
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  let checkInput = (i, name) => {
    let input = document.querySelector(`#${name}`);
    let label = document.querySelector(`[for='${name}']`);

    input.value !== ""
      ? label.classList.add("stick")
      : label.classList.remove("stick");

    let currInputs = inputsInfo;

    currInputs[i].value = input.value;

    if (name !== "fromEmail" && input.value !== "") {
      label.classList.add("green");
      label.classList.remove("red");
      input.classList.add("green-border");
      input.classList.remove("red-border");
      currInputs[i].valid = true;
    } else {
      label.classList.remove("green");
      label.classList.add("red");
      input.classList.remove("green-border");
      input.classList.add("red-border");
      currInputs[i].valid = false;
    }

    if (name === "fromEmail" && input.value !== "") {
      if (validateEmail(input.value)) {
        label.classList.add("green");
        label.classList.remove("red");
        input.classList.add("green-border");
        input.classList.remove("red-border");
        currInputs[i].valid = true;
      } else {
        label.classList.remove("green");
        label.classList.add("red");
        input.classList.remove("green-border");
        input.classList.add("red-border");
        currInputs[i].valid = false;
      }
    }
    setInputsInfo(currInputs);
    inputsValid();
  };

  const sendEmail = () => {
    setLoading(true);
    setTimeout(() => {
      setEmailSuccess(true);
      setTimeout(() => {
        setEmailSent(true);
        setLoading(false);
      }, 2000);
    }, 3000);
    emailjs
      .sendForm("service_1jag0wk", "template_r0a2x9c", "#contactForm")
      .then(
        (response) => {
          setLoading(false);
          console.log("SUCCESS!", response.status, response.text);
        },
        (error) => {
          console.log("FAILED...", error);
        }
      );
  };

  let inputs = inputsInfo.map((inp, i) => {
    if (inp.type === "textarea") {
      return (
        <div key={i}>
          <textarea
            type={inp.type}
            id={inp.name}
            name={inp.name}
            onInput={() => checkInput(i, inp.name)}
          />
          <label htmlFor={inp.name}>{inp.label}</label>
        </div>
      );
    }

    if (inp.type === "text") {
      return (
        <div key={i}>
          <input
            type={inp.type}
            id={inp.name}
            name={inp.name}
            onInput={() => checkInput(i, inp.name)}
          />
          <label htmlFor={inp.name}>{inp.label}</label>
        </div>
      );
    }

    return "";
  });
  return (
    <div className="contact">
      <div className="info">
        <h1>contact</h1>
        <p>
          Reach me on any of my social links, or send me a message with the form
          below.
        </p>
        <a
          href="https://discord.gg/fR4qF5shHb"
          target="blank"
          className="discord"
        >
          <Discord className="discordIcon" />
        </a>
        <a
          href="https://vm.tiktok.com/ZMRpH4mHT"
          target="blank"
          className="tiktok"
        >
          <SiTiktok />
        </a>
        <a
          href="https://www.twitch.tv/qtdragon_"
          target="blank"
          className="twitch"
        >
          <FaTwitch />
        </a>
      </div>
      {!emailSent ? (
        <form id="contactForm">
          {inputs}
          <button
            disabled={!validInputs}
            type="button"
            onClick={() => sendEmail()}
          >
            {loading ? (
              <div className={emailSuccess ? "loading-complete" : "loading"}>
                {emailSuccess && <FiCheck className="checkmark" />}
              </div>
            ) : (
              "Send"
            )}
          </button>
        </form>
      ) : (
        <div className="message-sent">
          <h3>Message Sent</h3>
          <p>I'll get back to you shortly</p>
        </div>
      )}
    </div>
  );
};

export default Contact;
