import "../styles/book.css";

const Book = () => {
  return (
    <div className="book">
      <div className="front"></div>
      <div className="spiral">
        <div className="ring"></div>
        <div className="ring"></div>
        <div className="ring"></div>
        <div className="ring"></div>
        <div className="ring"></div>
        <div className="ring"></div>
        <div className="ring"></div>
        <div className="ring"></div>
        <div className="ring"></div>
        <div className="ring"></div>
        <div className="ring"></div>
        <div className="ring"></div>
        <div className="ring"></div>
      </div>
      <div className="page1">
        <div className="chevron-tab"></div>
        <div className="green-tab"></div>
        <div className="stripe-tab">
          <div className="stripe-container">
            <span className="stripe-pink"></span>
            <span className="stripe-peach"></span>
            <span className="stripe-pink"></span>
            <span className="stripe-peach"></span>
            <span className="stripe-pink"></span>
            <span className="stripe-peach"></span>
            <span className="stripe-pink"></span>
            <span className="stripe-peach"></span>
            <span className="stripe-pink"></span>
            <span className="stripe-peach"></span>
            <span className="stripe-pink"></span>
            <span className="stripe-peach"></span>
          </div>
        </div>

        <div className="page-1-top">
          <div className="image img1">
            <div className="photo"></div>
          </div>
          <div className="image img2">
            <div className="photo"></div>
          </div>
        </div>
        <div className="page-1-bottom">
          <div className="about-me">
            <h3>
              <span className="blue-square"></span>A little bit about me
              <span className="blue-square"></span>
            </h3>
            <p>
              I am 18 years old, living in Europe and have been{" "}
              <span className="highlight h-blue">
                drawing since i can remember,
              </span>{" "}
              <span className="highlight h-purple">my other hobbies</span> are
              creating things out of wood or clay, playing games and{" "}
              <span className="highlight h-green">gardening</span>..
            </p>
          </div>
        </div>
      </div>
      <div className="page2">
        <div className="purple-tab"></div>

        <div className="text-columns">
          <div className="text-column"></div>
          <div className="text-column"></div>
        </div>

        {/* <span className="title"></span> */}

        <div className="info-grid">
          <div className="grid gr1"></div>
          <div className="grid gr2"></div>
          <div className="grid gr3"></div>
          <div className="grid gr4"></div>
        </div>
      </div>
    </div>
  );
};

export default Book;
